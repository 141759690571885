import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  Input,
  OnDestroy, OnInit,
  ViewChild
} from '@angular/core';
import { BottomSheetService } from '../../common/services/bottom-sheet.service';
import {SessionStorageService} from "ngx-webstorage";
import {environment} from "../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import { MAT_BOTTOM_SHEET_DATA } from '@angular/material';
import {MatBottomSheetRef} from "@angular/material/bottom-sheet";
import {SearchAddressService} from "../../common/services/searchaddress.service";
import {LeagueEventviewComponent} from "../league-eventview/league-eventview.component";
import { MatTabGroup } from '@angular/material/tabs';
import {Router} from "@angular/router";


@Component({
  selector: 'app-leagues',
  templateUrl: './leagues.component.html',
  styleUrls: ['./leagues.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class LeaguesComponent implements OnDestroy, AfterViewInit {

  @Input() nosearch: boolean;
  @Input() type: any;
  @ViewChild('tabGroup', { static: false }) tabGroup!: MatTabGroup;


  cards: any;
  xcards: any;

  s1: any;
  header: number;
  miles: number;
  timer: any;
  xcity: string;
  xstate: string;
  xevents: any;
  goodevents: number;


  constructor(
    private _router: Router,
    private ref: ChangeDetectorRef,
    private sessionSt: SessionStorageService,
    private bottomSheetService: BottomSheetService,
    private bottomSheetRef: MatBottomSheetRef,
    private http: HttpClient,
    private searchSubject: SearchAddressService,
    @Inject(MAT_BOTTOM_SHEET_DATA) public datax: any
  ) {

    this.goodevents = 0;
    this.header = 1;

    this.timer = setInterval(() => {
      this.ref.detectChanges();
    }, 750);

    if(this.datax.id.referal !== undefined){
      this.sessionSt.store('ambassador', this.datax.id.referal);
    }


    this.miles = 0;

    this.http.get<any>(environment.service.trackithubapi + '/api/outside/get_league_list?lat='+this.sessionSt.retrieve('current_lat')+'&lng='+this.sessionSt.retrieve('current_lng')).subscribe((data: any) => {
      this.cards = data;
      this.process();
      if(this.cards.length > 0){
        this.miles = this.cards[0].distance_in_miles;
      }
      this.ref.detectChanges();
    });

    this.http.get<any>(environment.service.trackithubapi + '/api/outside/xget_league_list?lat='+this.sessionSt.retrieve('current_lat')+'&lng='+this.sessionSt.retrieve('current_lng')).subscribe((data: any) => {
      this.xcards = data;
      this.xprocess();
      this.ref.detectChanges();
    });



    this.s1 = this.searchSubject.cast.subscribe(data => {
      this.cards = [];
      console.log(data.address_lat, data.address_lng);
      if(data.address_lat !== null && data.address_lng !== null) {
        this.http.get<any>(environment.service.trackithubapi + '/api/outside/get_league_list?lat=' + data.address_lat + '&lng=' + data.address_lng).subscribe((datax: any) => {
          this.process();
          if (this.cards.length > 0) {
            this.miles = this.cards[0].distance_in_miles;
          }
        });
      }
    });
  }

  process(){
    for (let i = 0; i < this.cards.length; i++) {
      for (let x = 0; x < this.cards[i].events.length; x++) {
        const e = this.cards[i].events[x];
        e.register_status = Number(e.register_status) || 0;
        e.waiting = Number(e.waiting) || 0;
        e.capacity = Number(e.capacity) || 0;
        e.waitingcapacity = Number(e.waitingcapacity) || 0;
        e.registered = Number(e.registered) || 0;
        e.full = 0;
        if(e.capacity != 0) {
          // capacity set
          if (e.registered != 0) {
            // someone has registered
            if (e.waitingcapacity == 0) {
              //no waiting list
              if (e.registered >= e.capacity) {
                e.full = 1;
              } else {
                e.full = 0;
              }
            } else {
              if ((e.registered+e.waiting)  >=  (e.waitingcapacity+e.capacity)) {
                e.full = 1;
              } else {
                e.full = 0;
              }
            }
          } else {
            // no one has registered yet
            e.full = 0;
          }
        } else {
          // no capacity
          e.full = 0;
        }
        this.cards[i].events[x] = e;
      }
    }
  }

  xprocess(){
    for (let i = 0; i < this.xcards.length; i++) {
      for (let x = 0; x < this.xcards[i].events.length; x++) {
        const e = this.xcards[i].events[x];
        e.register_status = Number(e.register_status) || 0;
        e.waiting = Number(e.waiting) || 0;
        e.capacity = Number(e.capacity) || 0;
        e.waitingcapacity = Number(e.waitingcapacity) || 0;
        e.registered = Number(e.registered) || 0;
        e.full = 0;
        if(e.capacity != 0) {
          // capacity set
          if (e.registered != 0) {
            // someone has registered
            if (e.waitingcapacity == 0) {
              //no waiting list
              if (e.registered >= e.capacity) {
                e.full = 1;
              } else {
                e.full = 0;
              }
            } else {
              if ((e.registered+e.waiting)  >=  (e.waitingcapacity+e.capacity)) {
                e.full = 1;
              } else {
                e.full = 0;
              }
            }
          } else {
            // no one has registered yet
            e.full = 0;
          }
        } else {
          // no capacity
          e.full = 0;
        }
        this.xcards[i].events[x] = e;
      }
    }
  }

  leagueevent(city, state, events) {



    console.log("sorry");

    this.header = 2;


    this.xcity = city;
    this.xstate = state;
    this.xevents = events;


    //this.bottomSheetService.openBottomSheetWithData(LeaguesEventsComponent, 'LeaguesEventsComponent',{'city': city, 'state': state, 'events': events, 'bottomref': this.bottomSheetRef});
    console.log("bi");
    this.ref.detectChanges();
    console.log("hi");

  }


  leagueinfo() {
    window.open('https://blog.trackithub.com/premier-pickleball-league/', '_blank');
  }

  openleague(rp_id, event_id, is_team){
    this.bottomSheetRef.dismiss();
    this.bottomSheetService.openBottomSheetWithDataCustomClass(LeagueEventviewComponent, 'LeagueEventviewComponent',{
      event:event_id,
      repeat_id: rp_id,
      paid: false,
      is_team: is_team
    }, 'custom-bottom');
  }

  ngAfterViewInit() {

    if(this._router.url.includes('/clinics')) {
      this.tabGroup.selectedIndex = 1;
    }
  }

  ngOnDestroy() {
    if (this.s1 !== undefined) {
      this.s1.unsubscribe();
    }
    clearInterval(this.timer);
  }

  openpage(){
    window.open('https://www.surveymonkey.com/r/N8N7YSD', '_blank');
  }

  gobacktoheader() {
    this.header = 1;
  }

  protected readonly Number = Number;
}
