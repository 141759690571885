import {ChangeDetectionStrategy, Component, Inject} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';



@Component({
  selector: 'app-rotation',
  templateUrl: './dashboard-rotation.component.html',
  styleUrls: ['./dashboard-rotation.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})

export class DashboardRotationComponent {


  players: number = 0;


  constructor(
    public dialogRef: MatDialogRef<DashboardRotationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      players: number;
    },
  ) {
    this.players = data.players;

    console.log('vv',this.players);



  }



}
