import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component, Inject,
  OnDestroy,
  OnInit, ViewChild,
} from '@angular/core';

import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {MatDialog} from '@angular/material/dialog';
import {ToastService} from "../../common/toast";
import { MatPaginator, MatSort, MatTable, MatTableDataSource } from '@angular/material';
import {environment} from "../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {AccountService} from "../../common/services/account.service";

@Component({
  selector: 'dashboard-league-score',
  templateUrl: './dashboard-league-score.component.html',
  styleUrls: ['./dashboard-league-score.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,

})



export class DashboardLeagueScoreComponent implements OnInit, OnDestroy, AfterViewInit {


  rrname: string;
  total: number;
  s1 = null;
  account: any;

  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;
  @ViewChild(MatTable, {static: false}) table: MatTable<any>;

  constructor(  @Inject(MAT_DIALOG_DATA) public data: any,  private http: HttpClient, public dialogRef: MatDialogRef<DashboardLeagueScoreComponent>, private accountService: AccountService, public dialog: MatDialog,private toastService: ToastService) {}

  listdata: any[] = this.data.data.data;
  displayedColumns: string[] = ['score_col','score'];
  dataSource = new MatTableDataSource();

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  ngOnInit() {
    console.log(this.data.data.data);
    this.rrname = this.data.data.data[1].roundrobin_name;




    console.log(this.rrname);
    this.dataSource.data = this.listdata;
    this.total = this.listdata.reduce((accumulator, item) => accumulator + Number(item.score), 0);


    for (let i = 0; i < this.listdata.length; i++) {
      console.log(this.listdata[i].score);
      if(this.listdata[i].score === '-1'){
        console.log('x');
        this.total++;
      }
    }



    this.s1 = this.accountService.cast.subscribe(data => {
      this.account = data;
    });

  }

  ngOnDestroy() {
    if (this.s1 != null) {
      this.s1.complete();
    }
  }

  onInputChange(row){


    if(Number(row.score) > Number(row.max)){
      row.score = 0;
      this.toastService.show({
        text: 'Score is over max.',
        type: 'warn',
        buttonText: '',
      });
    }
    this.total = this.listdata.reduce((accumulator, item) => accumulator + Number(item.score), 0);

    for (let i = 0; i < this.listdata.length; i++) {
      console.log(this.listdata[i].score);
      if(this.listdata[i].score === '-1'){
        console.log('x');
        this.total++;
      }
    }

  }

  save() {
    this.http.post<any>(environment.service.trackithubapi + '/api/inside/saveleaguetotal', {profile_id: this.account.current_profile.profile_id, data: this.dataSource.data }).subscribe((data: any) => {
      this.toastService.show({
        text: 'Scores have been updated.',
        type: 'success',
        buttonText: '',
      });
      this.dialogRef.close();
    });
  }


  cancel() {
    this.dialogRef.close();
  }


  selectInputText(event: FocusEvent): void {
    const inputElement = event.target as HTMLInputElement;
    inputElement.select();
  }

}
