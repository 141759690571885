
import {
  Component,
  OnInit,
  AfterViewInit,
  Inject,
  ChangeDetectionStrategy,
  OnDestroy,
  ChangeDetectorRef, ViewChild
} from '@angular/core';
import {MAT_BOTTOM_SHEET_DATA} from '@angular/material';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser'
import {environment} from '../../../environments/environment';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {AccountService} from '../../common/services/account.service';
import {ToastService} from '../../common/toast';
import {Router} from '@angular/router';

import {MatDialog} from '@angular/material/dialog';
import {MatBottomSheetRef} from '@angular/material';
import { SessionStorageService } from 'ngx-webstorage';

import {
  ICreateOrderRequest,
} from 'ngx-paypal';
import {
  DashboardEventviewcancelComponent, EventViewElement, InviteViewElement,
  myCustomIPayPalConfig
} from "../../dashboard";
import {LeagueRegisterComponent} from "../league-register/league-register.component";
import {LeagueRegisterTeamComponent} from "../league-register-team/league-register-team.component";
import {LeagueRegisterTeammateComponent} from "../league-register-teammate/league-register-teammate.component";
import {LeagueRegisterTeamInsideComponent} from "../league-register-teaminside/league-register-teaminside.component";
import {Account} from "../../common/interfaces/account";
import {ProfileComponent} from "../../profile/profile.component";
import {BottomSheetService} from "../../common/services/bottom-sheet.service";
import {SelectionModel} from "@angular/cdk/collections";
import {MatTable, MatTableDataSource} from "@angular/material/table";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";




@Component({
  selector: 'app-league-eventview',
  templateUrl: './league-eventview.component.html',
  styleUrls: ['./league-eventview.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})



export class LeagueEventviewComponent implements OnInit, AfterViewInit, OnDestroy {

  public payPalConfig?: myCustomIPayPalConfig;
  api_key: string;
  price: string;
  event: any;
  env: any;
  guestcount: number;
  emailsent: boolean;
  sheetRef: any = this.data.ref;

  listdata: EventViewElement[] = [{
    name: '',
    status: '',
    waiting: '',
    paid: '',
    owed: '',
    totalprice: '',
    fee: '',
    guestcount: '',
    id: null,
    profile_id: '',
    gateway: ''
  }];

  eventdetail: any = {
    'guestcount': 0,
    'billing_state': null,
    'paid': null,
    'owed': null,
    'withstanding': null,
    'overrideprice': null,
    'ev_id': null,
    'catid': null,
    'attendance_id': null,
    'summary': null,
    'description': null,
    'event_type': null,
    'dynamic_rp': null,
    'last_repeat': null,
    'next_repeat': null,
    'invite_id': null,
    'capacity': null,
    'waitingcapacity': null,
    'attending': null,
    'group_id': null,
    'repeat_id': null,
    'permissions': null,
    'invite_status': null,
    'startrepeat': null,
    'endrepeat': null,
    'date_start': null,
    'date_starts': null,
    'start_time': null,
    'time_stop': null,
    'reg_close': null,
    'cancel_close': null,
    'cancel_status': null,
    'reg_open': null,
    'created_by': null,
    'register_status': null,
    'confirmed': null,
    'waiting': null,
    'attendstate': null,
    'city': null,
    'state': null,
    'geolon': null,
    'geolat': null,
    'street': null,
    'postcode': null,
    'title': null,
    'event_started': null,
    'is_owner': null,
    'is_admin': null,
    'is_league' : null,
    'is_team' : null,
    'start_day': null,
    'start_dates': null,
    'attendance_list': [],
    'invite_list': [],
    'invitelist': [],
    'grouplist': [],
    'published': null,
    'overall_status': null,
    'custom_rsvp_message': null,
    'fee': null
  };

  content: SafeHtml;
  dateobj: any;
  account: any;
  calendar: any;
  isover: boolean;
  register: boolean;
  paypal: boolean;
  isDisabled: boolean = false;
  fake: boolean;
  alreadyinside: boolean = false;
  is_team: any;
  is_registered: any;
  s1: any;
  extra: string;
  url: string;

  displayedColumns: string[] = ['name', 'status'];

  selection = new SelectionModel<InviteViewElement>(true, []);


  dataSource = new MatTableDataSource();

  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  @ViewChild('MatSort', {static: false}) sort: MatSort;
  @ViewChild(MatTable, {static: false}) table: MatTable<EventViewElement>;



  constructor(@Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
              public dialog: MatDialog,
              private router: Router,
              private toastService: ToastService,
              private sanitizer: DomSanitizer,
              private http: HttpClient,
              private ref: ChangeDetectorRef,
              private sessionSt: SessionStorageService,
              public _router: Router,
              private accountService: AccountService,
              private bottomSheetRef: MatBottomSheetRef,
              private bottomSheetService: BottomSheetService) {}

  ngOnInit() {

    /**
    const dialogRef = this.dialog.open(LeagueRegisterComponent, {
      maxWidth: 500,
      minWidth: 370,
      disableClose: true,
      data: {}
    });
    **/

    this.paypal = false;
    this.env = environment;
    this.fake = false;
    this.guestcount = 1;
    this.is_registered = 0;



    if(this._router.url.includes('dashboard')) {
      this.s1 = this.accountService.cast.subscribe(data => {
        this.account = data;
        this.gotorepeat(this.data.id.event, this.data.id.repeat_id);
      });
    } else {
      this.gotorepeat(this.data.id.event, this.data.id.repeat_id);
    }




  }

  byPass(){

    if(this._router.url.includes('dashboard')) {
      this.sessionSt.store('joinoutside', this.eventdetail.group_id);
      this.sessionSt.store('leagueevent', this.data.id.repeat_id);
      window.location.href = '/dashboard/overview';
    } else {

      this.sessionSt.store('leagueevent', this.data.id.repeat_id);
      if (this.data.id.bypass == true) {
        this.sessionSt.store('pregame', true);
      }
      if (this.eventdetail.is_team === '1') {
        this.sessionSt.store('joinoutsideteam', this.eventdetail.group_id);
      } else {
        this.sessionSt.store('joinoutside', this.eventdetail.group_id);
      }

    }
  }

  recordActionReverse(){
    if(this.is_team !== '1' ) {
      window.location.href = '/registeruser/' + this.data.id.repeat_id;
    } else {
      window.location.href = '/registerteam/' + this.data.id.repeat_id;
      this.sessionSt.store('leagueeventteam', this.data.id.repeat_id);
    }
  }


  recordAction(){

    this.byPass();

    if(this.alreadyinside == true){
      if(this.is_team !== '1' ) {
        window.location.href = '/dashboard/overview';
      } else {

        this.sessionSt.store('leagueeventteam', this.data.id.repeat_id);
        const dialogRef = this.dialog.open(LeagueRegisterTeamInsideComponent, {
          maxWidth: 500,
          minWidth: 370,
          disableClose: true,
          data: {}
        });
      }
    } else {
      if (this.register == true) {

        if(this.is_team !== '1' ) {

          this.router.navigate(['/registeruser/' + this.data.id.repeat_id]);
        } else {

          this.router.navigate(['/registerteam/' + this.data.id.repeat_id]);
          this.sessionSt.store('leagueeventteam', this.data.id.repeat_id);
        }


      }  else {

        if(this.is_team !== '1' ) {
          this.bottomSheetRef.dismiss();
          this.router.navigate(['/login']);
        } else {
          this.router.navigate(['/registerteam/' + this.data.id.repeat_id]);
          this.sessionSt.store('leagueeventteam', this.data.id.repeat_id);
        }


      }
    }
  }

  onRegister(){
    this.paypal = true;
    this.register = true;
  }

  alreadyin(){
    this.paypal = true;
    this.register = false;
    this.alreadyinside = true;
  }

  onLogin(){
    this.paypal = true;
    this.register = false;
  }

  onRegisterBypass(){
    this.byPass();
    this.router.navigate(['/registerpregame/'+this.event]);
  }

  onLoginBypass(){
    this.byPass();
    this.bottomSheetRef.dismiss();
    this.router.navigate(['/login']);
  }
  ready() {
    this.sessionSt.store('last_start_date',this.eventdetail.date_start);
    this.listdata = this.eventdetail.attendance_list;
    this.eventdetail.description = this.eventdetail.description.replace(/h3/gi, 'p');
    this.dateobj = new Date(this.eventdetail.date_start);
    this.content = this.sanitizer.bypassSecurityTrustHtml(
      this.eventdetail.description
    );

    this.dataSource.data = this.listdata;



    if(!this._router.url.includes('dashboard')) {
      //Guess it does not matter if it is free or paid from the outside we will just figure it out on the the next page.
      this.data.id.paid = true;
      this.sessionSt.store('last_event_seen',this.data.id.event);
    }

    if(this.data.id.paid == true && this.data.id.is_team !== 1 && this.data.id.teammate == 0){
      const dialogRef = this.dialog.open(LeagueRegisterComponent, {
        maxWidth: 500,
        minWidth: 370,
        disableClose: true,
        data: {}
      });
    }

    if(this.data.id.paid == true && this.data.id.is_team == 1 && this.data.id.teammate === 0 && (this.sessionSt.retrieve('team_id') === undefined || this.sessionSt.retrieve('team_id') === '' || this.sessionSt.retrieve('team_id') === null)){
      const dialogRef = this.dialog.open(LeagueRegisterTeamComponent, {
        maxWidth: 500,
        minWidth: 370,
        disableClose: true,
        data: {}
      });
    }

    if(this.data.id.paid == true && this.data.id.is_team == 1 && this.data.id.teammate !== 0 && this.data.id.teammate !== undefined){
      const dialogRef = this.dialog.open(LeagueRegisterTeammateComponent, {
        maxWidth: 500,
        minWidth: 370,
        disableClose: true,
        data: {}
      });



    }


    if(this.data.id.referal !== undefined){
      this.sessionSt.store('ambassador', this.data.id.referal);
    }

    if(this.data.id.bypass == true){
      //this.byPass();
    }





    if (this.eventdetail.is_team === '0') {
      this.price = this.eventdetail.fee;
    } else {
      this.price = this.eventdetail.fee;
    }

    this.eventdetail.attending = Number(this.eventdetail.attending) || 0;
    this.eventdetail.capacity = Number(this.eventdetail.capacity) || 0;
    this.eventdetail.waiting = Number(this.eventdetail.waiting) || 0;
    this.eventdetail.waitingcapacity = Number(this.eventdetail.waitingcapacity) || 0;

    console.log(this.eventdetail);


    // , { name: 'buyer-country', value: 'US' }

    this.api_key = 'AatvF_ia1APg7tJB7n7G0DLrCgkDrqHwdTLT6co11NUkRBgFjiYHGoiIfK8jpEHq5fZOrjSYdwTiRj_h';

    if(this.data.id.event == 11475 || this.data.id.event == 11501){
      console.log('special override');
      //this.api_key = 'AXud3_cRCn_NdVCbvppfPbMpS2WUy8E-vECdaCGbe0DuvuZ6jl6Zzs7qPT3x3F7NREp-LlGuMXXf12et';
    }

    this.payPalConfig = {
      //clientId: 'AbTO1pXbxTeH7A21pvBdsNwU9xBewrqLdt9eV8lgzvjmCLDCpa9I7lIeG-ieAjPniMULei-SLIN3IdyI',
      clientId: this.api_key,
      currency: 'USD',
      vault: 'true',
      style: {
        label: 'paypal',
        layout: 'vertical',
        size: 'small',
        shape: 'pill',
        color: 'silver',
        tagline: false,
      },

      createOrderOnClient: (data) => <ICreateOrderRequest> {
        intent: 'CAPTURE',
        purchase_units: [{
          amount: {
            currency_code: 'USD',
            value: this.price,
            breakdown: {
              item_total: {
                currency_code: 'USD',
                value: this.price
              }
            }
          },
          items: [
            {
              name: this.eventdetail.summary,
              quantity: "1",
              unit_amount: {
                currency_code: "USD",
                value: this.price,
              },
            },
          ],
        }]
      },
      advanced: {
        commit: 'true',
        extraQueryParams: [{ name: 'enable-funding', value: 'venmo' }], // Enable Venmo funding
      },
      onApprove: (data, actions) => {

        actions.order.get().then(details => {



          if(this.sessionSt.retrieve('ambassador')){

            this.http.post(environment.service.trackithubapi + "/api/outside/record_ambassador", {
              repeat_id: this.data.id.repeat_id,
              event_name: this.eventdetail.summary,
              order_id: details.id,
              payer_id: details.payer.payer_id,
              first_name: details.payer.name.given_name,
              last_name: details.payer.name.surname,
              price: details.purchase_units[0].amount.value,
              ambassador_id: this.sessionSt.retrieve('ambassador')}, {}).subscribe((data: any) => {
              this.sessionSt.clear('ambassador');
              this.sessionSt.store('ambassador_row',data.result);
            });

          }

          this.recordAction();
        });
      },
      onClientAuthorization: (data) => {


      },
      onCancel: (data, actions) => {
        this.toastService.show({
          text: 'You have canceled your order.',
          type: 'warn',
        });
      },
      onError: err => {
        this.toastService.show({
          text: 'There has been an error please contact support.',
          type: 'warn',
        });
      },
      onClick: (data, actions) => {

      },
    };

    this.onRegister();

    this.ref.detectChanges();


  }

  closeit(){
    this.bottomSheetRef.dismiss();
  }


  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  gotorepeat(event, rp) {

    this.event = event;

    if(this._router.url.includes('dashboard')) {
      this.extra = '&profile_id=' + this.account.current_profile.profile_id;
      this.url = '/api/inside/calendar_events?rp_id=';
    } else {
      this.extra = '';
      this.url = '/api/outside/calendar_events?rp_id=';
    }
    this.http.get<any>(environment.service.trackithubapi + this.url + rp + '&event=' + event + this.extra).subscribe((data: any) => {
      this.eventdetail = data;


      if(this._router.url.includes('dashboard')) {
        if(this.eventdetail == 'null' || this.eventdetail == null){
          // drop back to outside
          this.http.get<any>(environment.service.trackithubapi + '/api/outside/calendar_events?rp_id=' + rp + '&event=' + event + this.extra).subscribe((data: any) => {
            this.eventdetail = data;
            this.is_team = this.eventdetail.is_team;
            this.http.get<any>(environment.service.trackithubapi + '/api/inside/check_register_status?rp_id=' + rp + '&attendance_id=' + this.eventdetail.attendance_id).subscribe((data: any) => {
              this.is_registered = data.status;
              this.eventdetail.is_registered = this.is_registered;
              this.ready();
            });
          });
        } else {
          this.is_team = this.eventdetail.is_team;
          this.http.get<any>(environment.service.trackithubapi + '/api/inside/check_register_status?rp_id=' + rp + '&attendance_id=' + this.eventdetail.attendance_id).subscribe((data: any) => {
            this.is_registered = data.status;
            this.eventdetail.is_registered = this.is_registered;
            this.ready();
          });
        }


      } else {
        this.eventdetail.is_registered = this.is_registered;
        this.ready();
      }

    });
  }

  recordAttendee(event, atd, rp, status, fee, drp, ty) {
    if(status == 0){
      if(ty == 0) {
        const dialogRef = this.dialog.open(DashboardEventviewcancelComponent, {
          width: 'auto',
          height: 'auto',
          data: {
            'is_team': this.eventdetail.is_team
          }
        });

        dialogRef.afterClosed().subscribe(result => {
          if(result == true){
            this.isDisabled = true;
            this.ref.detectChanges();

            const headers = new HttpHeaders({       'Accept': 'text/html'});
            const input = new FormData();
            input.append('jevattend', status);
            input.append('jevattend_hidden', '1');
            input.append('jevattend_id', '0');
            input.append('jevattend_id_approve', '0');
            input.append('guestcount', this.guestcount.toString(10));
            input.append('lastguest', '1');
            input.append('Itemid', '1');

            if (fee != '') {
              input.append('radioselect', '0');
              input.append('xmlfile[field6]', '1');
              input.append('xmlfile[feebalance]', (fee * this.guestcount).toString(10));
              input.append('xmlfile[totalfee]', (fee * this.guestcount).toString(10));
              input.append('xmlfile[field9]', 'paypalipn');
            }

            this.http.post(environment.service.trackithubapi + '/api/inside/cancelregistration?profile_id='+this.sessionSt.retrieve('current_profile')+'&at_id='+atd+'&rp_id='+rp+'&is_team' + this.eventdetail.is_team, input, {headers}).subscribe((data: any) => { }, error => {
              if(this.eventdetail.is_team == 1){
                this.toastService.show({
                  text: 'Team registration has been canceled.',
                  type: 'success',
                });
                window.location.href = '/dashboard/overview';
              } else {
                this.toastService.show({
                  text: 'Not Attending Event.',
                  type: 'success',
                });
              }
              this.ref.detectChanges();
              this.gotorepeat(event, rp);
            });


          } else {

          }
        });

      }
    }
  }

  openProfile(profile_id, profile_type) {
    if(this.env.service.profile == true) {
      this.http.get<Account>(environment.service.trackithubapi + '/api/inside/loadprofile?clean_group=yes&profile=' + profile_id).subscribe((profileData: any) => {
        this.bottomSheetService.openBottomSheetWithDataCustomClass(ProfileComponent, 'ProfileComponent',{
          'profileData': profileData,
          'type': profile_type
        }, 'custom-bottom-p' );

      });
    }
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
  }

  ngOnDestroy() {
    if (this.s1 != null) {
      this.s1.complete();
    }
  }

  protected readonly Number = Number;
}

