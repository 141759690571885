import {Component, ElementRef, OnDestroy, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {LocationService} from '../../common/services/location.service';
import {Account} from '../../common/interfaces/account';
import {environment} from '../../../environments/environment';
import {PanelService} from '../../common/services/panel.service';
import {PickleballPlaceDetailsComponent} from '../../frontend/pickleball-place-details/pickleball-place-details.component';
import { PanelCardService } from '../../common/services/panelcard.service';
import {LeagueEventviewComponent} from '..';
import {ActivatedRoute, Router} from "@angular/router";
import {MatSidenav} from '@angular/material';
import {BottomSheetService} from "../../common/services/bottom-sheet.service";
import {MatDialog} from "@angular/material/dialog";
import {SessionStorageService} from "ngx-webstorage";
import {LeaguesComponent} from "../leagues/leagues.component";
import {TermRegisterComponent} from "../term-register/term-register.component";
import {ToastService} from "../../common/toast";


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})

export class HomeComponent implements OnInit, OnDestroy {

  card: any;
  cards: any;
  location: any;
  modcards: any;
  s1 = null;

  @ViewChild('dynamic', {static: false, read: ViewContainerRef}) view: ViewContainerRef;
  @ViewChild('appDrawer', {static: true}) appDrawer: ElementRef;
  @ViewChild('drawer', {static: true}) drawer: MatSidenav;

  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private _router: Router,
    private locationService: LocationService,
    private panelService: PanelService,
    private panelCardService: PanelCardService,
    private bottomSheetService: BottomSheetService,
    public dialog: MatDialog,
    private sessionSt: SessionStorageService,
    private router: Router,
    private toastService: ToastService,

  ) {
    this.s1 = this.locationService.cast.subscribe(data => {
      this.location = data;
      if(this.location.lng !== null) {
        this.http.get<Account>(environment.service.trackithubapi + '/api/outside/clubscloseby?lng=' + this.location.lng + '&lat=' + this.location.lat).subscribe((data: any) => {
          this.cards = data.result;
          this.modcards = this.removeDuplicates(this.cards, 'id');
        });
      }
    });
  }

  ngOnDestroy() {
    if (this.s1 != null) {
      this.s1.complete();
    }
  }

  ngOnInit() {

    if(this._router.url.includes('/leagues') || this._router.url.includes('/clinics')){
      this.bottomSheetService.openBottomSheetWithDataCustomClass(LeaguesComponent, 'LeaguesComponent',{referal: this.route.snapshot.params.ambassador}, 'custom-bottom-pp');
    }

    if(this._router.url.includes('/attendees')){
      const dialogRef = this.dialog.open(TermRegisterComponent, {
        maxWidth: 500,
        minWidth: 370,
        maxHeight: '90vh', // Adjust this based on your requirements
        disableClose: true,
        data: {}
      });
    }

    if(this._router.url.includes('/pregame')){
      this.bottomSheetService.openBottomSheetWithDataCustomClass(LeaguesComponent, 'LeaguesComponent',{referal: this.route.snapshot.params.ambassador}, 'custom-bottom-p');
    }


    if(this.route.snapshot.params.id !== undefined){
      this.http.get<Account>(environment.service.trackithubapi + '/api/outside/getcard?card=' + this.route.snapshot.params.id).subscribe((data: any) => {
        this.card = data.result;
        this.sendCard(this.card);
      });
    }



    if(this.route.snapshot.params.team_id !== undefined && this.route.snapshot.params.rp_id !== undefined){

      this.http.get<Account>(environment.service.trackithubapi + '/api/outside/shareexpired?team_id=' + this.route.snapshot.params.team_id).subscribe((data: any) => {
        if(data.result == true) {
          this.sessionSt.store('leagueeventteam', this.route.snapshot.params.rp_id);
          this.sessionSt.store('team_id', this.route.snapshot.params.team_id);
          this.router.navigate(['/registerteam/' + this.route.snapshot.params.rp_id + '/' + this.route.snapshot.params.team_id]);
        } else {
          this.toastService.show({
            text: 'This share link has expired.',
            type: 'alert',
            buttonText: '',
          });
        }
      });
    }


    if(this.route.snapshot.params.league !== undefined){



      this.http.get<Account>(environment.service.trackithubapi + '/api/outside/forcelogout').subscribe((data: any) => {
        this.http.get<any>(environment.service.trackithubapi + '/api/outside/calendar_events?rp_id=' + this.route.snapshot.params.league + '&event=').subscribe((data: any) => {



          if(data === null){
            this.bottomSheetService.openBottomSheetWithDataCustomClass(LeagueEventviewComponent, 'LeagueEventviewComponent', {
              event: this.route.snapshot.params.league,
              referal: this.route.snapshot.params.ambassador,
              repeat_id: this.route.snapshot.params.league,
              paid: false,
              is_team: 0
            }, 'custom-bottom');
          } else {
            this.bottomSheetService.openBottomSheetWithDataCustomClass(LeagueEventviewComponent, 'LeagueEventviewComponent', {
              event: this.route.snapshot.params.league,
              referal: this.route.snapshot.params.ambassador,
              repeat_id: this.route.snapshot.params.league,
              paid: false,
              is_team: data.is_team
            }, 'custom-bottom');
          }




        });
      });
    }

    if(this.route.snapshot.params.pregame !== undefined){
      this.http.get<Account>(environment.service.trackithubapi + '/api/outside/forcelogout').subscribe((data: any) => {
        this.bottomSheetService.openBottomSheetWithDataCustomClass(LeagueEventviewComponent, 'LeagueEventviewComponent',{
          event:this.route.snapshot.params.pregame,
          repeat_id: this.route.snapshot.params.pregame,
          referal: this.route.snapshot.params.ambassador,
          paid: false,
          bypass: true,
        }, 'custom-bottom');

      });

    }



    console.log(this.route.snapshot.params, this.sessionSt.retrieve('leagueeventteam'), this.sessionSt.retrieve('teammate'), this.sessionSt.retrieve('leagueevent'));

    if(this.route.snapshot.params.toleagueteam !== undefined && this.route.snapshot.params.team !== undefined) {

      if (this.sessionSt.retrieve('leagueeventteam') != '' && this.sessionSt.retrieve('leagueeventteam') != null && this.sessionSt.retrieve('leagueeventteam') != undefined) {



        this.sessionSt.store('teammate', this.route.snapshot.params.team);
        this.sessionSt.store('leagueevent', this.sessionSt.retrieve('leagueeventteam'));

        this.bottomSheetService.openBottomSheetWithDataCustomClass(LeagueEventviewComponent, 'LeagueEventviewComponent', {
          referal: this.route.snapshot.params.ambassador,
          event: this.route.snapshot.params.toleagueteam,
          repeat_id: this.route.snapshot.params.toleagueteam,
          paid: true,
          is_team: 1,
          teammate: this.route.snapshot.params.team
        }, 'custom-bottom');
      }
    }


    if(this.route.snapshot.params.toleagueteam !== undefined && (this.sessionSt.retrieve('team_id') === undefined || this.sessionSt.retrieve('team_id') !== null || this.sessionSt.retrieve('team_id') !== '')) {

console.log('what');

      if (this.sessionSt.retrieve('leagueevent') != '' && this.sessionSt.retrieve('leagueevent') != null && this.sessionSt.retrieve('leagueevent') != undefined) {
        this.bottomSheetService.openBottomSheetWithDataCustomClass(LeagueEventviewComponent, 'LeagueEventviewComponent', {
          referal: this.route.snapshot.params.ambassador,
          event: this.route.snapshot.params.toleagueteam,
          repeat_id: this.route.snapshot.params.toleagueteam,
          paid: true,
          is_team: 1,
          teammate: 0
        }, 'custom-bottom');
      }
    }



    if(this.route.snapshot.params.toleague !== undefined) {
      if (this.sessionSt.retrieve('leagueevent') != '' && this.sessionSt.retrieve('leagueevent') != null && this.sessionSt.retrieve('leagueevent') != undefined) {
        this.bottomSheetService.openBottomSheetWithDataCustomClass(LeagueEventviewComponent, 'LeagueEventviewComponent', {
          referal: this.route.snapshot.params.ambassador,
          event: this.route.snapshot.params.toleague,
          repeat_id: this.route.snapshot.params.toleague,
          paid: true,
          is_team: 0,
          teammate: 0
        }, 'custom-bottom');
      }
    }

    if(this.route.snapshot.params.toleaguebypass !== undefined) {
      if (this.sessionSt.retrieve('leagueevent') != '' && this.sessionSt.retrieve('leagueevent') != null && this.sessionSt.retrieve('leagueevent') != undefined) {
        this.bottomSheetService.openBottomSheetWithDataCustomClass(LeagueEventviewComponent, 'LeagueEventviewComponent', {
          event: this.route.snapshot.params.toleaguebypass,
          referal: this.route.snapshot.params.ambassador,
          repeat_id: this.route.snapshot.params.toleaguebypass,
          paid: true,
          bypass: true
        }, 'custom-bottom');
      }
    }

  }

  sendCard(card) {
    this.panelCardService.selectCard(card, null, null);
    this.panelService.openPanel(PickleballPlaceDetailsComponent,'PickleballPlaceDetailsComponent');
  }

  removeDuplicates(originalArray, objKey) {
    let trimmedArray = [];
    let values = [];
    let value;

    for(let i = 0; i < originalArray.length; i++) {
      value = originalArray[i][objKey];
      if ( values.indexOf(value) === -1 ) {
        trimmedArray.push(originalArray[i]);
        values.push(value);
      }
    }

    return trimmedArray;

  }

}
